import React from 'react';
import { keyframes } from '@emotion/core';
import styled from '@emotion/styled';
import { ClassNameProp } from '../utils/types';

const wave = keyframes`
  0% { transform: rotate(0.0deg) }
  10% { transform: rotate(16.0deg) }
  20% { transform: rotate(-6.0deg) }
  30% { transform: rotate(16.0deg) }
  40% { transform: rotate(-4.0deg) }
  50% { transform: rotate(16.0deg) }
  60% { transform: rotate( 0.0deg) }
  100% { transform: rotate( 0.0deg) }
`;

const Wave = styled.span`
  animation: ${wave} 2.1s 0.6s;
  transform-origin: 75% 75%;
  display: inline-block;
`;

type Props = ClassNameProp;

const WavingHand = ({ className }: Props) => <Wave className={className}>👋</Wave>;

export default WavingHand;
