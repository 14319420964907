/** @jsx jsx */
import { jsx, Text, Link as TLink } from 'theme-ui';
import { Link } from 'gatsby';
import Layout from './layout';
import Title from './title';
import Listing from './listing';
import useMinimalBlogConfig from '../hooks/use-minimal-blog-config';
import replaceSlashes from '../utils/replaceSlashes';
import WavingHand from './waving-hand';

type PostsProps = {
  posts: {
    slug: string;
    title: string;
    date: string;
    excerpt: string;
    description: string;
    timeToRead?: number;
    tags?: {
      name: string;
      slug: string;
    }[];
  }[];
};

const Homepage = ({ posts }: PostsProps) => {
  const { basePath, blogPath } = useMinimalBlogConfig();

  return (
    <Layout>
      <section sx={{ mb: [5, 6], p: { fontSize: [1, 2, 3], mt: 2 } }}>
        <Text sx={{ fontSize: [4, 5, 6], fontWeight: `bold`, color: `heading` }}>
          Welcome
          <WavingHand sx={{ marginLeft: '6px', marginRight: '8px' }} />!
        </Text>
        <p>
          My name is Martin. I am software developer working mostly with React those days, former
          mathematics competitor, cooking and fitness enthusiast.
        </p>
        <p>
          This is my personal website where I am going to share my knowledge about software
          development, things I learn and am passionate about.
        </p>
        <p>
          I hope you will enjoy your time here, find useful information and thank you for visiting.
        </p>
      </section>
      <Title text="Latest Posts">
        <TLink
          sx={{ variant: 'links.secondary' }}
          as={Link}
          to={replaceSlashes(`/${basePath}/${blogPath}`)}
        >
          Read all posts
        </TLink>
      </Title>
      <Listing posts={posts} showTags={false} />
    </Layout>
  );
};

export default Homepage;
